body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-div{
  background-size: contain!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
}

.app-card{
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
  border:none!important;
  border-radius: 1.285714rem!important;
}

.app-card:hover{
  box-shadow: black 1px 1px 6px -2px!important;
}

.app-card-blocked{
  -webkit-filter: grayscale(100%)!important; /* Safari 6.0 - 9.0 */
  filter: grayscale(100%)!important;
}

.app-modal{
  width: 90%!important;
  height: 75%!important;
}

.new-game-logo-card{
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: contain!important;
}

.card-mail{
  background-image: url('/mail.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
  background-color: white;
}

.card-ad{
  background-image: url('/ad.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-share{
  background-image: url('/share.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-database{
  background-image: url('/database.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-hr{
  background-image: url('/hr.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-settings{
  background-image: url('/settings.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-administration{
  background-image: url('/administration.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-application{
  background-image: url('/application.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-browser{
  background-image: url('/browser.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-market{
  background-image: url('/market.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-ransomware{
  background-image: url('/ransomware.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-unknown{
  background-image: url('/unknown.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-kalifat{
  background-image: url('/kalifat.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}


.card-coffee{
  background-image: url('/coffee.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.card-unplug{
  background-image: url('/unplug.png')!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.email-menu{
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

.email-menu:hover{
  background:#dbe6e9;
}

.new-mail-btn, .email-list{
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

.new-mail-btn:hover{
  background-color: #a4d0db!important
}

.email-list:hover{
  background-color: #e8f6ff!important
}

.ui.fullscreen.modal.transition {
  left:initial!important;
}

.ascii-art{
  font-family: monospace;
}
.ad-user-detail > div > a{
padding-top:2px!important;
height:25px!important;
padding-bottom:2px!important;
padding-left:10px!important;
padding-right:10px!important;
}

.ad-user-detail > div {
  border-radius: 0px!important;
  margin-bottom:0px!important;
  border:0px;
  box-shadow: none;
}

.share-row{
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

.share-row:hover{
background-color: #34c1a039!important
}

.react-contextmenu{
  top:150px!important
}

.db-logo-image{
  background-image: url('/db-con.png')!important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.administration-menu-item:hover{
background: rgb(178, 215, 238);
}

.organization-status-div{
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: black 1px 1px 5px -2px;
}

.blur-bg-div{
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.logo-company{
  width:120px;
  height:50px;
  float:left;
  background-size: contain!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.organisation-bg{
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.challenges-table > div > div > div > div > div > table > tbody > tr:hover{
  cursor: pointer;
}

.new-message-editor{
  min-height: 150px;
}

.ransomware-input-key > input {
  background: transparent!important;
  color:#43da93!important;
  border:1px solid #43da93!important;
}

.budget-logo-list{
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.ransomware-bg-mode{
  background-color: #434343;
}

.discovery-map{
  background-image: url('/discovery_map.png')!important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.discovery-placeholder{
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  fill: #1b2a4f;
}

.discovery-placeholder:hover{
  stroke: #00dac8;
  stroke-width:3px;
  fill:#3991b4;
}

#modal-header-dark{
background-color: #2d2d2d!important;
}
.app-modal-dark{
background-color: black!important;
}

.app-modal-dark>div{
  background-color: black!important;
  color:white!important;
}

.dark-mode-on{
  color:#a7a7a7!important;
}

.ad-accordion > div > .content.active{
  padding: 0px!important;
}

/* TOOOBOLX XXXXXXXXXXXXXXXXXXXXXXXXXX */
.sc-bxivhb, .sc-jTzLTM{
  height: 0px!important;
  visibility: hidden!important;
}

.terminal-base > div {
  height:500px!important;
  width: 100%;
}

.terminal-base > div > div {
  overflow: hidden!important;
  
}

pre{
  overflow: hidden!important;
}

.terminal-base{
  max-width: initial!important; 
  height:initial!important; 
  min-height: initial!important; 
  max-height: initial!important;
}
.sc-EHOjeç{
  max-height: 500px!important;
  overflow:auto!important;
}
/*
.discovery-placeholder>use:hover{
  fill:#1b4085;
}
*/

/* MARKET ************************************/
.market-item-segment:hover{
  box-shadow: black 1px 1px 5px 0px!important;
}

.market-item-segment{
  -webkit-transition: all 300ms linear!important;
-ms-transition: all 300ms linear!important;
transition: all 300ms linear!important;
}

.decision-modal{
  background: transparent!important;
  border: 0px!important; 
  box-shadow: initial!important;
}


/* BROWSER  #################################"*/
.browser-loading-bar{
  height: 3px;
}

.browser-loading-bar>.bar{
  height: 3px!important;
}

.bg-darkweb{
background-size: cover!important;
}

.logo-div-menu{
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.misp-logo{
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: contain!important;
}

.cnpd-banner{
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap; 
  overflow: auto;
}
.flex-item {
  flex: 0 0 auto;
}


.mature-modal{
  background-color:#fd45e7d9!important;
  border-radius: 10px;
  color:white!important;
}
/*FAKE APP  #################################"*/

.code-input>input{
  background: transparent!important;
  color: #2185d0!important;
}

.recrutement-image{
  height: 125px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover!important;
  background-position: center!important;
}

/* ########## FINAL ##################*/

.dark-modal-b{
  background:transparent!important;
}
.dark-modal-b > .header{
  background:transparent!important;
  color:white!important;
}

.dark-modal-b > .content{
  background:transparent!important;
  color:white!important;
}

.triangulator-input > input{
  background:transparent!important;
  color:rgb(19, 173, 129)!important;
}

.triangulator-input {
  background:transparent!important;
  border:1px solid rgb(19, 173, 129);
  border-radius:4px
}

.final_countdown{
  -moz-animation: final_countdown 2s  infinite!important;
  -webkit-animation: final_countdown 2s  infinite!important;
  -o-animation: final_countdown 2s  infinite!important;
    animation: final_countdown 2s  infinite!important;
    animation-iteration-count:infinite;
}

.active-dot{
  -moz-animation: underligned 1s infinite!important;
  -webkit-animation: underligned 1s infinite!important;
  -o-animation: underligned 1s infinite!important;
    animation: underligned 1s infinite!important;
    animation-iteration-count:infinite;
}

@keyframes final_countdown {
  0% { transform: scale(1.2) }
  50% {  transform: scale(.8); color:red }
  100% { transform: scale(1.2) }
}

@-moz-keyframes final_countdown {
  0% { transform: scale(1.2) }
  50% {  transform: scale(.8); color:red }
  100% { transform: scale(1.2) }
}

@-webkit-keyframes final_countdown {
  0% { transform: scale(1.2) }
  50% {  transform: scale(.8); color:red }
  100% { transform: scale(1.2) }
}

@keyframes underligned {
  0% { border-color:#1678c2 }
  50% {  border-color:#103a5a }
  100% { border-color:#1678c2) }
}

@-moz-keyframes underligned {
  0% { border-color:#1678c2 }
  50% {  border-color:#103a5a }
  100% { border-color:#1678c2 }
}

@-webkit-keyframes underligned {
  0% { border-color:#1678c2 }
  50% {  border-color:#103a5a }
  100% { border-color:#1678c2 }
}